import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function WakeMeUp() {
  return (
    <Layout>
      <Helmet title="Wake Me Up | Avicii + Aloe Blacc" />

      <h1>Wake Me Up</h1>

      <h2>Avicii + Aloe Blacc</h2>

      <h4>Key: G</h4>

      <h4>Tempo: 92</h4>

      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>Feeling my way through the darkness</p>
          <p>Guided by a beating heart</p>
          <p>I can't tell where the journey will end</p>
          <p>But I know where to start</p>
        </Verse>
        <Verse>
          <p>They tell me I'm too young to understand</p>
          <p>They say I'm caught up in a dream</p>
          <p>Well life will pass me by if I don't open up my eyes</p>
          <p>Well that's fine by me</p>
        </Verse>
        <Chorus>
          <p>So wake me up when it's all over</p>
          <p>When I'm wiser and I'm older</p>
          <p>All this time I was finding myself, and I</p>
          <p>Didn't know I was lost</p>
          <p>So wake me up when it's all over</p>
          <p>When I'm wiser and I'm older</p>
          <p>All this time I was finding myself, and I</p>
          <p>Didn't know I was lost</p>
        </Chorus>
        <Verse>
          <p>I tried carrying the weight of the world</p>
          <p>But I only have two hands</p>
          <p>Hope I get the chance to travel the world</p>
          <p>But I don't have any plans</p>
        </Verse>
        <Verse>
          <p>Wish that I could stay forever this young</p>
          <p>Not afraid to close my eyes</p>
          <p>Life's a game made for everyone</p>
          <p>And love is a prize</p>
        </Verse>
        <Chorus>
          <p>So wake me up when it's all over</p>
          <p>When I'm wiser and I'm older</p>
          <p>All this time I was finding myself, and I</p>
          <p>Didn't know I was lost</p>
          <p>So wake me up when it's all over</p>
          <p>When I'm wiser and I'm older</p>
          <p>All this time I was finding myself, and I</p>
          <p>I didn't know I was lost</p>
          <p>I didn't know I was lost</p>
          <p>I didn't know I was lost</p>
          <p>I didn't know I was lost</p>
          <p>I didn't know</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
